/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
            github
            note
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js


  return (
    <div className="bio2">
      <StaticImage
        className="bio-avatar"
        layout="fixed"
        src="../images/mob.jpg"
        width={124}
        height={124}
        quality={95}
        alt="Profile picture"
      />
      <p>
        あんまり更新しないかもしれないです。99年生。
        <br></br>
        Live on the Internet.<br></br>
      </p>
      
    </div>
  )

  // return (
  //   <div className="bio">
  //     <StaticImage
  //       className="bio-avatar"
  //       layout="fixed"
  //       formats={["AUTO", "WEBP", "AVIF"]}
  //       src="../images/profile-pic.png"
  //       width={50}
  //       height={50}
  //       quality={95}
  //       alt="Profile picture"
  //     />
  //     {author?.name && (
  //       <p>
  //         Written by <strong>{author.name}</strong> {author?.summary || null}
  //         {` `}
  //         <a href={`https://twitter.com/${social?.twitter || ``}`}>
  //           You should follow them on Twitter
  //         </a>
  //       </p>
  //     )}
  //   </div>
  // )
}

export default Bio
